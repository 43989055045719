import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import Input from "../../components/FormElements/Input/Input";
import Button from "../../components/FormElements/Button/Button";
import Card from "../../components/Card/Card";
import { VALIDATOR_EMAIL, VALIDATOR_MINLENGTH } from "../../util/validators";
import { useForm } from "../../hooks/form-hook";
import { AuthContext } from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";
import "./Login.css";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

function Login() {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();

  const [formState, inputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  async function SubmitHandler(event: any) {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BASE_URL}/api/auth/login`,
        "POST",
        JSON.stringify({
          email: formState.inputs.email.value,
          password: formState.inputs.password.value,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      auth.login(responseData.userId, responseData.token, responseData.isAdmin);
      navigate("/");
    } catch (err) {}
  }

  function switchModeHandler() {
    navigate("/user/register");
  }

  return (
    <React.Fragment>
      <ErrorModal
        onCancel={clearError}
        header="An Error Occurred!"
        show={!!error}
        footer={<Button onClick={clearError}>Okay</Button>}
      >
        <p>{error}</p>
      </ErrorModal>
      <div className="login-container">
        <Card className="loginCard">
            {isLoading && <LoadingSpinner asOverlay/>}
                <h2 className="font-orbitron">Login</h2>
                <hr />
                <form onSubmit={SubmitHandler}>
                    <div className="inputContainer">     
                        <Input
                            id="email"
                            type="text"
                            element="input"
                            label="Email"
                            validators={[VALIDATOR_EMAIL()]}
                            errorText="Please enter a valid email."
                            onInput={inputHandler}
                        />
                        <Input
                            id="password"
                            type="password"
                            element="input"
                            label="Password"
                            validators={[VALIDATOR_MINLENGTH(6)]}
                            errorText="Please enter a valid password."
                            onInput={inputHandler}
                        />
                    </div>
                <Button type="submit" size={25} disabled={!formState.isValid}>
                    LOG IN
                </Button>
                </form>
                <button className="switchButton" onClick={switchModeHandler}>
                Don't have an account yet? Create one
                </button>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default Login;
