import React, { useState, Suspense } from "react";
import { motion } from "framer-motion";
import { textVariant, staggerContainer } from "../util/motion";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Stars, Preload } from "@react-three/drei";
import Ball from "./3Dobject/Ball";
import CanvasLoader from "./Loader";
import { workExperiences } from "../constant";
import Spaceship from "./3Dobject/Spaceship";

const Experience = (props: any) => {
  const [url, setUrl] = useState("/spaceship1.glb");
  return (
    <motion.section
      variants={staggerContainer()}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className="max-w-[112rem] mx-auto c-space my-20 relative z-0 py-[10vh] px-[10vw]"
    >
      <motion.div variants={textVariant(0)}>
        <p className="text-customPink sm:text-[18px] text-[14px] text-center text-secondary uppercase tracking-wider font-orbitron">
          What I have done so far
        </p>
        <h2 className="text-white font-black md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px] text-center font-orbitron">
          Work Experience.
        </h2>
      </motion.div>

      <div className="grid lg:grid-cols-3 grid-cols-1 gap-5 mt-12">
        <div className="col-span-1 rounded-lg bg-black-200 border border-black-300">
          <Canvas>
            <ambientLight intensity={3} />
            <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
            <directionalLight position={[10, 10, 10]} intensity={1} />
            <OrbitControls enableZoom={false} maxPolarAngle={Math.PI / 2} />

            <Suspense fallback={<CanvasLoader />}>
              <Spaceship url={url} />
            </Suspense>
            <OrbitControls
              autoRotate
              enablePan={false}
              enableZoom={false}
              maxPolarAngle={Math.PI / 2}
              minPolarAngle={Math.PI / 2}
            />
            <Stars radius={500} depth={50} count={1000} factor={10} />
          </Canvas>
        </div>

        <div className="col-span-2 rounded-lg bg-black-200 border border-black-300">
          <div className="sm:py-10 py-5 sm:px-5 px-2.5">
            {workExperiences.map((item, index) => (
              <div
                key={index}
                onClick={() => setUrl(item.url)}
                onPointerOver={() => setUrl(item.url)}
                onPointerOut={() => setUrl(item.url)}
                className="grid grid-cols-[auto_1fr] items-start gap-5  transition-all ease-in-out duration-500 cursor-pointer hover:bg-black-300 rounded-lg sm:px-5 px-2.5 group"
              >
                <div className="flex flex-col h-full justify-start items-center py-2">
                  <div className="rounded-3xl w-20 h-20 bg-black-600">
                    <Canvas
                      frameloop="demand"
                      dpr={[1, 2]}
                      gl={{ preserveDrawingBuffer: true }}
                    >
                      <ambientLight intensity={1} />
                      <directionalLight position={[0, 0, 0.05]} />
                      <directionalLight position={[0, 0, -0.05]} />
                      <Suspense fallback={<CanvasLoader />}>
                        <OrbitControls
                          enableZoom={false}
                          maxPolarAngle={Math.PI / 2}
                          minPolarAngle={Math.PI / 2}
                        />
                        <Ball imgUrl={item.icon} scale={2.3} />
                      </Suspense>

                      <Preload all />
                    </Canvas>
                  </div>

                  <div className="flex-1 w-0.5 mt-4 h-full bg-black-300 group-hover:bg-black-500 group-last:hidden;" />
                </div>

                <div className="sm:p-5 px-2.5 py-5">
                  <div className="flex items-center justify-between gap-3">
                    <div className="flex items-center gap-3">
                      <p className="font-bold text-white-800 text-base sm:text-lg">
                        {item.name}
                      </p>

                      {item.tags.map((tag) => (
                        <div key={tag.id} className="w-4 h-4 sm:w-6 sm:h-6 flex-shrink-0">
                          <img
                            src={tag.path}
                            alt={`${tag.name}`}
                            className="object-contain w-full h-full"
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  <p className="text-sm sm:text-base mb-5 text-white-800">
                    {item.pos} -- <span>{item.duration}</span>
                  </p>

                  <p className="text-white-700 transition-all ease-in-out duration-500 mt-2 sm:mt-4">
                    {item.title}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Experience;
