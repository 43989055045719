import React, { useEffect, useState, useContext } from "react";

import Button from "../../components/FormElements/Button/Button";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useHttpClient } from "../../hooks/http-hook";
import { useParams, useNavigate } from "react-router-dom";
import img1 from "../../Assets/portfolio_pp.png";
import { months } from "../../constant";
import "./Post.css";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import Markdown from "markdown-to-jsx";
import Circle from "../../components/Shapes/Circle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "../../hooks/form-hook";
import Input from "../../components/FormElements/Input/Input";
import { VALIDATOR_MINLENGTH } from "../../util/validators";
import { AuthContext } from "../../context/auth-context";
import Modal from "../../components/Modal/Modal";
import Pagination from "../../components/Pagination/Pagination";

function Post() {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [identifiedBlog, setIdentifiedBlog] = useState<any>();
  const [comments, setComments] = useState<any[]>([]);
  const [visibility, setVisibility] = useState(false);
  const blogId = useParams().blogId;
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const postsPerPage: number = 5;

  // pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentComments = comments?.slice(indexOfFirstPost, indexOfLastPost);

  const [formState, inputHandler] = useForm(
    {
      text: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BASE_URL}/api/blog`
        );
        setIdentifiedBlog(responseData.blogs.find((p: any) => p.id === blogId));
      } catch (err) {}
    };
    const fetchComments = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BASE_URL}/api/comment/${blogId}`
        );
        setComments(responseData);
      } catch (err) {}
    };
    fetchBlog();
    fetchComments();
  }, [sendRequest, blogId]);

  const goBack = () => {
    navigate(-1);
  };

  const submitHandler = async (event: any) => {
    event.preventDefault();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BASE_URL}/api/comment/${blogId}`,
        "POST",
        JSON.stringify({
          text: formState.inputs.text.value,
        }),
        {
          Authorization: "Bearer: " + auth.token,
          "Content-Type": "application/json",
        }
      );
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BASE_URL}/api/comment/${blogId}`
      );
      setComments(responseData);
    } catch (err) {}
  };

  const popupCloseHandler = (e: boolean) => {
    setVisibility(e);
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <ErrorModal
        onCancel={clearError}
        header="An Error Occurred!"
        show={!!error}
        footer={<Button onClick={clearError}>Okay</Button>}
      >
        <p>{error}</p>
      </ErrorModal>
      <Modal
        onClose={popupCloseHandler}
        show={visibility}
        title="You need an account"
      >
        <p style={{ fontSize: 20, marginTop: 50 }}>
          You haven't login yet, Please login to use the comment section.
        </p>
        <button
          className="loginbutton"
          onClick={() => {
            setVisibility(false);
            navigate("/user/login");
          }}
        >
          Login
        </button>
      </Modal>
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && identifiedBlog && (
        <div className="postPage">
          <div className="singlePost">
            <button className="goBackButton" onClick={goBack}>
              <FontAwesomeIcon icon={faArrowLeft} size="2x" color="white" />
              <p style={{ marginLeft: 20, fontSize: 18, color: "white" }}>
                go back
              </p>
            </button>
            <div className="postHeader">
              <h3
                className="text-lightViolet"
                style={{
                  fontSize: 18,
                  fontWeight: "normal",
                  marginTop: 20,
                }}
              >
                Published on{" "}
                {months[new Date(identifiedBlog.createdAt).getMonth()] +
                  " " +
                  new Date(identifiedBlog.createdAt).getDate() +
                  ", " +
                  new Date(identifiedBlog.createdAt).getFullYear()}
              </h3>
              <h1 className="singlePostTitle">{identifiedBlog.title}</h1>
              <div className="autorContainer">
                <Circle
                  width="60px"
                  height="60px"
                  className="pCircle"
                  backgroundColor="#f1f1f1"
                  borderWidth="0px"
                >
                  <img src={img1} alt="" />
                </Circle>
                <div style={{ marginLeft: 10 }}>
                  <p style={{ lineHeight: 0.3, fontSize: 16, color: "white" }}>
                    By Bryan Christiano
                  </p>
                  <p style={{ lineHeight: 0.3, fontSize: 16, color: "white" }}>
                    Software Developer | Engineer
                  </p>
                </div>
              </div>
            </div>

            <hr
              style={{
                width: 50,
                marginLeft: 0,
                marginTop: 30,
                marginBottom: 30,
              }}
            />

            <Markdown>{identifiedBlog.description}</Markdown>

            <div className="post-form">
              <div className="post-decor">
                <h3 style={{ marginLeft: 10 }} className="font-orbitron">Comment section</h3>
              </div>
              <form onSubmit={submitHandler}>
                {!auth.token ? (
                  <button
                    type="button"
                    className="fakeInput"
                    onClick={(e) => setVisibility(!visibility)}
                  >
                    <p className="fakePlaceholder">Create a comment</p>
                  </button>
                ) : (
                  <div className="inputContainer2">
                    <Input
                      id="text"
                      element="textarea"
                      type="text"
                      placeholder="Create a comment"
                      rows={6}
                      validators={[VALIDATOR_MINLENGTH(5)]}
                      onInput={inputHandler}
                    />
                  </div>
                )}
                <div className="submit-button">
                  <Button
                    type="submit"
                    register={true}
                    disabled={!formState.isValid}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>

            {currentComments &&
              currentComments.map((comment) => {
                return (
                  <div style={{ marginBottom: 10 }} key={comment._id}>
                    <div>
                      <h1
                        className="font-orbitron"
                        style={{
                          lineHeight: 0.3,
                          fontSize: 18,
                          color: "#FF4D6D",
                        }}
                      >
                        {comment.creatorName}
                      </h1>
                      <h3
                        style={{
                          lineHeight: 0.3,
                          fontWeight: "normal",
                          fontSize: 16,
                          color: "#898989",
                        }}
                      >
                        {`Posted on ` +
                          months[new Date(comment.createdAt).getMonth()] +
                          " " +
                          new Date(comment.createdAt).getDate() +
                          ", " +
                          new Date(comment.createdAt).getFullYear()}
                      </h3>
                    </div>
                    <p style={{ fontSize: 16, color: "white" }}>
                      {comment.text}
                    </p>
                  </div>
                );
              })}
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={comments.length}
              paginate={paginate}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Post;
