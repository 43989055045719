// import emailjs from '@emailjs/browser';
import { useRef, useState } from "react";
import { motion } from "framer-motion";
import { staggerContainer, textVariant } from "../util/motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import Alert from "./Alert";
import useAlert from "../hooks/useAlert-hook";
import { useHttpClient } from "../hooks/http-hook";

const Contact = () => {
  const formRef: any = useRef();

  const { alert, showAlert, hideAlert } = useAlert();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [form, setForm] = useState({ name: "", email: "", message: "" });

  const handleChange = ({
    target: { name, value },
  }: {
    target: { name: any; value: any };
  }) => {
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BASE_URL}/api/contact/send`,
        "POST",
        JSON.stringify({
          fullname: form.name,
          email: form.email,
          message: form.message,
        }),
        {
          "Content-Type": "application/json",
        }
      );

      if (responseData.success) {
        showAlert({
          text: "Thank you for your message 😃",
          type: "success",
        });

        setTimeout(() => {
          hideAlert();
          setForm({
            name: "",
            email: "",
            message: "",
          });
        }, 3000);
      } else {
        showAlert({
          text: "I didn't receive your message 😢",
          type: "danger",
        });

        setTimeout(() => {
          hideAlert();
          setForm({
            name: "",
            email: "",
            message: "",
          });
          clearError();
        }, 3000);
      }
    } catch (err) {}
  };

  return (
    <section className="c-space mt-20" id="contact">
      {alert.show && <Alert {...alert} />}
      <div className="relative min-h-screen flex items-center justify-center flex-col bg-black-200 py-20">
        <motion.div
          variants={staggerContainer()}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.25 }}
          className="max-w-xl relative z-10 sm:px-10 px-5 mt-12 text-center"
        >
          <motion.h3
            variants={textVariant(0)}
            className="sm:text-4xl text-3xl font-semibold text-customPink"
          >
            Let's Talk
          </motion.h3>
          <motion.p
            variants={textVariant(1)}
            className="text-lg text-white-600 mt-3"
          >
            If you're looking to hire a dedicated software developer, feel free
            to reach out. I'm eager to collaborate on exciting projects and help
            bring your ideas to life.
          </motion.p>

          <form
            ref={formRef}
            onSubmit={handleSubmit}
            className="mt-12 flex flex-col space-y-7"
          >
            <label className="space-y-3">
              <span className="text-lg text-white-600">Full Name</span>
              <input
                type="text"
                name="name"
                value={form.name}
                onChange={handleChange}
                required
                className="w-full bg-black-300 px-5 py-2 min-h-14 rounded-lg placeholder:text-white-500 text-lg text-white-800 shadow-black-200 shadow-2xl focus:outline-none"
                placeholder="ex., John Doe"
              />
            </label>

            <label className="space-y-3">
              <span className="text-lg text-white-600">Email Address</span>
              <input
                type="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                required
                className="w-full bg-black-300 px-5 py-2 min-h-14 rounded-lg placeholder:text-white-500 text-lg text-white-800 shadow-black-200 shadow-2xl focus:outline-none"
                placeholder="ex., johndoe@gmail.com"
              />
            </label>

            <label className="space-y-3">
              <span className="text-lg text-white-600">Your Message</span>
              <textarea
                name="message"
                value={form.message}
                onChange={handleChange}
                required
                rows={5}
                minLength={25}
                className="w-full bg-black-300 px-5 py-2 min-h-14 rounded-lg placeholder:text-white-500 text-lg text-white-800 shadow-black-200 shadow-2xl focus:outline-none"
                placeholder="Share your thoughts or inquiries..."
              />
            </label>

            <motion.button
              variants={textVariant(2)}
              className="bg-customPink px-5 py-2 min-h-12 rounded-lg shadow-black-200 shadow-2xl flex justify-center items-center text-lg text-white gap-3"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Sending..." : "Send Message"}

              <FontAwesomeIcon size={"2xs"} icon={faPaperPlane} />
            </motion.button>
          </form>
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;
