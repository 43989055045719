import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { Suspense, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import {
  Center,
  OrbitControls,
  useGLTF,
  ContactShadows,
  Environment,
} from "@react-three/drei";
import { motion } from "framer-motion";
import { staggerContainer, textVariant } from "../util/motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faChevronRight,
  faChevronLeft,
  faMobileScreenButton,
  faLaptop,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";

import CanvasLoader from "./Loader";
import { myProjects } from "../constant";
import Laptop from "./3Dobject/Laptop";
import Smartphone from "./3Dobject/Smartphone";
import Lander from "./3Dobject/Lander";

const projectCount = myProjects.length;

const Projects = (props: any) => {
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(0);
  const currentProject = myProjects[selectedProjectIndex];
  const [displayMode, setDisplayMode] = useState(
    currentProject.texture.default
  );

  const handleNavigation = (direction: any) => {
    setSelectedProjectIndex((prevIndex) => {
      if (direction === "previous") {
        return prevIndex === 0 ? projectCount - 1 : prevIndex - 1;
      } else {
        return prevIndex === projectCount - 1 ? 0 : prevIndex + 1;
      }
    });
  };

  useEffect(() => {
    setDisplayMode(currentProject.texture.default);
  }, [currentProject]);

  useGSAP(() => {
    gsap.fromTo(
      `.animatedText`,
      { opacity: 0 },
      { opacity: 1, duration: 1, stagger: 0.2, ease: "power2.inOut" }
    );
  }, [selectedProjectIndex]);

  return (
    <motion.section
      variants={staggerContainer()}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className="max-w-[112rem] mx-auto relative z-0 py-[10vh] px-[10vw]"
    >
      <motion.div variants={textVariant(0)}>
        <p className="sm:text-4xl text-3xl font-semibold text-white font-orbitron">
          My Selected Work
        </p>
      </motion.div>

      <div className="grid lg:grid-cols-2 grid-cols-1 mt-12 gap-8 w-full">
        <div className="flex flex-col gap-5 bg-coolMidnight relative sm:p-10 py-10 px-5 shadow-2xl shadow-black-300">
          <div className="absolute top-0 right-0">
            <img
              src={currentProject.spotlight}
              alt="spotlight"
              className="w-full h-96 object-cover rounded-xl"
            />
          </div>

          <div className="flex items-center justify-between">
            <div
              className="p-3 backdrop-filter backdrop-blur-3xl w-fit rounded-lg"
              style={currentProject.logoStyle}
            >
              <img
                className="w-10 h-10 shadow-sm"
                src={currentProject.logo}
                alt="logo"
              />
            </div>

            <div className="flex gap-2 z-10">
              {currentProject.texture.laptop && (
                <button
                  onClick={() => setDisplayMode("laptop")}
                  className={`px-3 py-3 rounded-md z-10 ${
                    displayMode === "laptop" ? "border border-black-300" : ""
                  }`}
                  style={{
                    backgroundColor:
                      displayMode === "laptop" ? "#FF4D6D" : "#272730",
                  }}
                >
                  <FontAwesomeIcon icon={faLaptop} size="1x" color="white" />
                </button>
              )}
              {currentProject.texture.mobile && (
                <button
                  onClick={() => setDisplayMode("mobile")}
                  className={`px-4 py-3 rounded-md z-10 ${
                    displayMode === "mobile" ? "border border-black-300" : ""
                  }`}
                  style={{
                    backgroundColor:
                      displayMode === "mobile" ? "#FF4D6D" : "#272730",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMobileScreenButton}
                    size="1x"
                    color="white"
                  />
                </button>
              )}
              {currentProject.texture.rocket && (
                <button
                  onClick={() => setDisplayMode("rocket")}
                  className={`px-4 py-3 rounded-md z-10 ${
                    displayMode === "rocket" ? "border border-black-300" : ""
                  }`}
                  style={{
                    backgroundColor:
                      displayMode === "rocket" ? "#FF4D6D" : "#272730",
                  }}
                >
                  <FontAwesomeIcon icon={faRocket} size="1x" color="white" />
                </button>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-5 text-white-600 z-20">
            <p className="text-customPink text-2xl font-semibold animatedText font-orbitron">
              {currentProject.title}
            </p>

            <p className="text-white text-[14px] font-montserrat animatedText">
              {currentProject.desc}
            </p>
            <p className="text-white text-[14px] font-montserrat animatedText">
              {currentProject.subdesc}
            </p>
          </div>

          <div className="flex items-center justify-between flex-wrap gap-5">
            <div className="flex items-center gap-3">
              {currentProject.tags.map((tag, index) => (
                <div
                  key={index}
                  className="w-10 h-10 rounded-md p-2 bg-neutral-100 bg-opacity-10 backdrop-filter backdrop-blur-lg flex justify-center items-center"
                >
                  <img src={tag.path} alt={tag.name} />
                </div>
              ))}
            </div>

            {selectedProjectIndex == 1 ? (
              <p className="text-white text-[12px] font-montserrat">
                {currentProject.urltitle}
              </p>
            ) : (
              <a
                className="flex items-center gap-2 cursor-pointer z-10"
                href={currentProject.href}
                target="_blank"
                rel="noreferrer"
              >
                <p className="text-white text-[12px] font-montserrat">
                  {currentProject.urltitle}
                </p>
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  size="1x"
                  color="white"
                />
              </a>
            )}
          </div>

          <div className="flex justify-between items-center mt-2">
            <button
              className="w-10 h-10 p-3 cursor-pointer active:scale-95 transition-all rounded-full"
              onClick={() => handleNavigation("previous")}
            >
              <FontAwesomeIcon icon={faChevronLeft} size="1x" color="#FF4D6D" />
            </button>

            <button
              className="w-10 h-10 p-3 cursor-pointer active:scale-95 transition-all rounded-full"
              onClick={() => handleNavigation("next")}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                size="1x"
                color="#FF4D6D"
              />
            </button>
          </div>
        </div>

        <div
          className="border border-black-300 bg-black-200 rounded-lg h-96 md:h-full"
          style={{ background: "#202025" }}
        >
          <Canvas camera={{ position: [-5, 10, -15], fov: 55 }}>
            <pointLight position={[10, 10, 10]} intensity={1.5} />
            <Suspense fallback={null}>
              {displayMode === "laptop" && (
                <Center>
                  <Laptop texture={currentProject.texture.laptop} />
                </Center>
              )}
              {displayMode === "mobile" && (
                <Smartphone
                  texture={currentProject.texture.mobile}
                  position={[0, 0, 0]}
                />
              )}
              {displayMode === "rocket" && <Lander position={[0,-2,0]} scale={1.5}/>}
              <Environment preset="city" />
            </Suspense>
            <ContactShadows
              position={[0, -5.5, 0]}
              scale={20}
              blur={2}
              far={6}
            />
            <OrbitControls maxPolarAngle={Math.PI / 2} enableZoom={false} />
          </Canvas>
        </div>
      </div>
    </motion.section>
  );
};

export default Projects;
