import React, { useState, createRef, useEffect, useContext } from "react";
import Modal from "../../components/Modal/Modal";
import Markdown from "markdown-to-jsx";
import { useForm } from "../../hooks/form-hook";
import Button from "../../components/FormElements/Button/Button";
import Input from "../../components/FormElements/Input/Input";
import BlogItem from "../../components/BlogItem";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { AuthContext } from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";
import { VALIDATOR_REQUIRE } from "../../util/validators";
import { motion } from "framer-motion";

import "./Blog.css";

function Blog() {
  const [formState, inputHandler] = useForm(
    {
      title: {
        value: "",
        isValid: false,
      },
      description: {
        value: "",
        isValid: false,
      },
      image: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const [visibility, setVisibility] = useState(false);
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState<any>();
  const [isValid, setIsValid] = useState<boolean>();
  const filePickerRef = createRef<any>();
  const [activeTab, setActiveTab] = useState(true);
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedBlogs, setLoadedBlogs] = useState<any>([]);

  const popupCloseHandler = (e: boolean) => {
    setVisibility(e);
  };

  const handleClick = (index: boolean) => {
    setActiveTab(index);
  };

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BASE_URL}/api/blog`
        );
        let sortedBlogs = responseData.blogs.sort(
          (a: { createdAt: Date }, b: { createdAt: Date }) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setLoadedBlogs(sortedBlogs);
      } catch (err) {}
    };
    fetchBlogs();
  }, [sendRequest]);

  function pickedHandler(event: any) {
    let pickedFile: any;
    let fileIsValid: any = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
    inputHandler("image", pickedFile, fileIsValid);
  }

  function pickImageHandler() {
    filePickerRef.current.click();
  }

  async function SubmitHandler(event: any) {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("title", formState.inputs.title.value);
      formData.append("description", formState.inputs.description.value);
      formData.append("creator", auth.userId);
      formData.append("image", formState.inputs.image.value);
      await sendRequest(
        `${process.env.REACT_APP_BASE_URL}/api/blog/write`,
        "POST",
        formData,
        {
          Authorization: "Bearer: " + auth.token,
        }
      );
      setVisibility(false);
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BASE_URL}/api/blog`
      );
      let sortedBlogs = responseData.blogs.sort(
        (a: { createdAt: Date }, b: { createdAt: Date }) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setLoadedBlogs(sortedBlogs);
    } catch (err) {}
  }

  return (
    <React.Fragment>
      <ErrorModal
        onCancel={clearError}
        header="An Error Occurred!"
        show={!!error}
        footer={<Button onClick={clearError}>Okay</Button>}
      >
        <p>{error}</p>
      </ErrorModal>
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      <div className="scroll-blog">
        {!isLoading && auth.isLoggedIn && auth.isAdmin && (
          <div className="px-10 mb-5">
            <motion.h1
              className="text-white text-3xl font-semibold"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              Welcome Back Admin!
            </motion.h1>
            <motion.button
              onClick={(e) => setVisibility(!visibility)}
              className="bg-customPink text-white px-4 py-2 rounded-lg mt-3"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              Create
            </motion.button>
            <Modal
              onClose={popupCloseHandler}
              show={visibility}
              title="Create Post"
            >
              <motion.form
                className="writeForm"
                onSubmit={SubmitHandler}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <div className="writeImg">
                  <button
                    type="button"
                    onClick={pickImageHandler}
                    className="cursor-pointer"
                  >
                    {previewUrl ? (
                      <img src={previewUrl} alt="" />
                    ) : (
                      <div className="bg-black-200 text-white-500 p-3 rounded-lg">
                        Pick Image
                      </div>
                    )}
                  </button>
                </div>
                <input
                  id={"image"}
                  ref={filePickerRef}
                  type="file"
                  accept=".jpg,.png,.jpeg"
                  onChange={pickedHandler}
                  className="hidden"
                />
                <div className="writeInput">
                  <Input
                    id="title"
                    element="input"
                    type="text"
                    placeholder="Title"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter your title here."
                    onInput={inputHandler}
                  />
                </div>

                <div className="tabBox2">
                  <div className="tab2">
                    <motion.button
                      type="button"
                      className="tablinks bg-black-200 text-white px-4 py-2 rounded-md"
                      style={{
                        backgroundColor: activeTab ? "#ddd" : "#f1f1f1",
                      }}
                      onClick={() => handleClick(true)}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      Edit
                    </motion.button>
                    <motion.button
                      type="button"
                      className="tablinks bg-black-200 text-white px-4 py-2 rounded-md"
                      style={{
                        backgroundColor: !activeTab ? "#ddd" : "#f1f1f1",
                      }}
                      onClick={() => handleClick(false)}
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      Preview
                    </motion.button>
                  </div>

                  <div>
                    {activeTab ? (
                      <div className="writeInput">
                        <Input
                          id="description"
                          element="textarea"
                          type="text"
                          placeholder="Tell your story"
                          rows={18}
                          validators={[VALIDATOR_REQUIRE()]}
                          errorText="Please enter your description here."
                          onInput={inputHandler}
                          initialValue={formState.inputs.description.value}
                          initialValid={formState.inputs.description.isValid}
                        />
                      </div>
                    ) : (
                      <div className="overflow-hidden h-21vw p-2">
                        <div className="h-full w-full overflow-auto">
                          <Markdown>
                            {formState.inputs.description.value}
                          </Markdown>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="writeSubmit">
                  <Button
                    type="submit"
                    size={25}
                    disabled={!formState.isValid}
                  >
                    Save
                  </Button>
                </div>
              </motion.form>
            </Modal>
          </div>
        )}

        {!isLoading && loadedBlogs && (
          <div className="px-10 py-12">
            <div className="textContainer">
              <span className="textstyle--1 font-orbitron">
                GO CHECK MY <span className="text-customPink font-orbitron">BLOGS</span>
              </span>
              <span className="textstyle--2 text-lightViolet font-orbitron">
                Thoughts, Educations, News, Software, Technology,
                Entrepreneurship, Finance, Business.
              </span>
              <span className="textstyle--3 text-white-600 font-montserrat">
                I started writing online in 2023, mostly about Tech, Finance,
                Entrepreneurship. I started writing to record what I had learned
                and studied. Feel free to read my blog, your thoughts and
                feedback are highly appreciated!
              </span>
            </div>
            <motion.h1
              className="LatestPost text-white-700 text-2xl font-bold mt-8"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              All Posts
            </motion.h1>
            <motion.div
              className="AllBlogs mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              {loadedBlogs.map((blog: any, index: number) => (
                <motion.div
                  key={blog.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  className="rounded-lg bg-black-300 p-5"
                >
                  <BlogItem
                    id={blog.id}             
                    imageUrl={blog.imageUrl}
                    createdAt={blog.createdAt}
                    title={blog.title}
                    description={blog.description}
                    creatorName={blog.creatorName}
                    tempUrl={blog.tempUrl}
                  />
                </motion.div>
              ))}
            </motion.div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default Blog;
