import React from "react";
import { useGLTF } from '@react-three/drei'

export const FLOOR_HEIGHT = 2.3;
export const NB_FLOORS = 2;

const Blackhole = (props: any) => {
    const { nodes }: any = useGLTF("/blackhole.glb");
    return (
      <group {...props} rotation={[Math.PI / 1.9, 0, 0]} position={[0, -5, 0]} scale={3}>
        {nodes.blackhole001.children.map((node: any, index: number) => (
          <mesh key={index} geometry={node.geometry} material={node.material} />
        ))}
      </group>
    );
};

export default Blackhole;
