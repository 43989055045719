import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import Card from "../../components/Card/Card";
import Input from "../../components/FormElements/Input/Input";
import Button from "../../components/FormElements/Button/Button";
import { AuthContext } from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";
import { useForm } from "../../hooks/form-hook";
import "./Register.css";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../util/validators";
// import ErrorModal from "../../components/ErrorModal";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ErrorModal from "../../components/ErrorModal/ErrorModal";

function Register() {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();

  //initialize the value and use custom hooks
  const [formState, inputHandler] = useForm(
    {
      FirstName: {
        value: "",
        isValid: false,
      },
      LastName: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  function switchModeHandler() {
    navigate("/user/login");
  }

  async function SubmitHandler(event: any) {
    event.preventDefault();
    console.log(formState);
    try{
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BASE_URL}/api/auth/signup`,
        "POST",
        JSON.stringify({
          firstName: formState.inputs.FirstName.value,
          lastName: formState.inputs.LastName.value,
          email: formState.inputs.email.value,
          password: formState.inputs.password.value,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      auth.login(responseData.userId, responseData.token, responseData.isAdmin);
      navigate("/");
    }catch(err){}
  }

  return (
    <React.Fragment>
      <ErrorModal
        onCancel={clearError}
        header="An Error Occurred!"
        show={!!error}
        footer={<Button onClick={clearError}>Okay</Button>}
      >
        <p>{error}</p>
      </ErrorModal>
      <div className="register-container">
        <Card className="registerCard">
          {isLoading && <LoadingSpinner asOverlay/>}
          <h2 className="font-orbitron">Register</h2>
          <hr />
          <form onSubmit={SubmitHandler}>
            <div className="inputContainer">
                <Input
                id="FirstName"
                type="text"
                element="input"
                label="First Name"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter your first name."
                onInput={inputHandler}
                />
                <Input
                id="LastName"
                type="text"
                element="input"
                label="Last Name"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter your last Name."
                onInput={inputHandler}
                />
                <Input
                id="email"
                type="text"
                element="input"
                label="Email"
                validators={[VALIDATOR_EMAIL()]}
                errorText="Please enter a valid email."
                onInput={inputHandler}
                />
                <Input
                id="password"
                type="password"
                element="input"
                label="Password"
                validators={[VALIDATOR_MINLENGTH(6)]}
                errorText="Please enter a password (min 6 characters)."
                onInput={inputHandler}
                />
            </div>
            <Button type="submit" size={25} disabled={!formState.isValid}>
              SIGN UP
            </Button>
          </form>
          <button className="switchButton1" onClick={switchModeHandler}>
            Already have an account? Log in now.
          </button>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default Register;
