import React, { useRef, useEffect } from "react";

import Header from "../../components/Header/Header";
import About from "../../components/About";
import Projects from "../../components/Projects";
import "./Home.css";
import Experience from "../../components/Experience";
import PromoteBlog from "../../components/PromoteBlog";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer/Footer";

function Home() {
  const scrollRef = useRef<any>();
  const scroll = useRef(0);

  // Define the scroll handler
  const handleScroll = () => {
    // Calculate the scroll position relative to the window
    const scrollTop = window.scrollY;
    const scrollHeight =
      document.documentElement.scrollHeight - window.innerHeight;

    // Update the scroll.current with the scroll percentage
    scroll.current = scrollTop / scrollHeight;
  };

  // Add window scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div ref={scrollRef} className="scroll">
      <Header scroll={scroll} />
      <About />
      <Projects />
      <Experience />
      <PromoteBlog />
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;
