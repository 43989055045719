import React, { useRef, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import MainNavigation from "./components/navigations/MainNavigation/MainNavigation";
import ScrollToTop from "./components/ScrollToTop";
import Post from "./pages/Post/Post";
import { AuthContext } from "./context/auth-context";
import { useAuth } from "./hooks/auth-hook";
import Footer from "./components/Footer/Footer";

import Home from "./pages/Home/Home";
import Blog from "./pages/Blog/Blog";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";

function App() {
  const { token, login, logout, userId, isAdmin } = useAuth();

  let routes;
  if (token) {
    routes = (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Go-Blog" element={<Blog />} />
        <Route path="/blogPost/:blogId" element={<Post />} />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Go-Blog" element={<Blog />} />
        <Route path="/user/login" element={<Login />} />
        <Route path="/user/register" element={<Register />} />
        <Route path="/blogPost/:blogId" element={<Post />} />
      </Routes>
    );
  }

  const starfieldRef: any = useRef();

  // Generate random stars and add them to the starfield
  useEffect(() => {
    const starfield: any = starfieldRef.current;

    const numberOfStars = 50;
    for (let i = 0; i < numberOfStars; i++) {
      const star = document.createElement("div");
      star.classList.add("star");

      // Randomize the star position and size
      const xPos = Math.random() * 100;
      const yPos = Math.random() * 100;
      const size = Math.random() + 1; // Random size between 1px and 3px
      star.style.left = `${xPos}%`;
      star.style.top = `${yPos}%`;
      star.style.width = `${size}px`;
      star.style.height = `${size}px`;

      // Append the star to the starfield
      starfield.appendChild(star);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        isAdmin: isAdmin,
        login: login,
        logout: logout,
      }}
    >
      <Router>
        <ScrollToTop />
        <div className="starry-background" style={{ minHeight: "100vh" }}>
          <div className="starfield" ref={starfieldRef}></div>
          <MainNavigation />
          {routes}
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
