import React, { useEffect, useRef } from "react";
import { useGLTF, useVideoTexture, RoundedBox } from "@react-three/drei";
import gsap from "gsap";

const Smartphone = (props: any) => {
  const group = useRef<any>();
  const { nodes, materials, animations }: any = useGLTF("/smartphone.glb");

  const txt = useVideoTexture(
    props.texture ? props.texture : "/video/project1.mp4"
  );

  useEffect(() => {
    gsap.from(group.current.rotation, {
      y: Math.PI / 2,
      duration: 1,
      ease: "power3.out",
    });
  }, [txt]);

  return (
    <group
      ref={group}
      scale={2}
      position={props.position}
      rotation={[0, Math.PI, 0]}
    >
      <group dispose={null}>
        <mesh name="screen" position={[0, 0, 0.09]}>
          <planeGeometry args={[2.2, 4.5]}/>
          <meshBasicMaterial map={txt}/>
        </mesh>
        <mesh
          name="Object_8"
          geometry={nodes["Object_8"].geometry}
          material={nodes["Object_8"].material}
        />
        <mesh
          name="Object_10"
          geometry={nodes["Object_10"].geometry}
          material={nodes["Object_10"].material}
        />
        <mesh
          name="Object_12"
          geometry={nodes["Object_12"].geometry}
          material={nodes["Object_12"].material}
        />
        <mesh
          name="Object_13"
          geometry={nodes["Object_13"].geometry}
          material={nodes["Object_13"].material}
        />
        <mesh
          name="Object_15"
          geometry={nodes["Object_15"].geometry}
          material={nodes["Object_15"].material}
        />
        <mesh
          name="Object_16"
          geometry={nodes["Object_16"].geometry}
          material={nodes["Object_16"].material}
        />
        <mesh
          name="Object_17"
          geometry={nodes["Object_17"].geometry}
          material={nodes["Object_17"].material}
        />
        <mesh
          name="Object_18"
          geometry={nodes["Object_18"].geometry}
          material={nodes["Object_18"].material}
        />
        <mesh
          name="Object_19"
          geometry={nodes["Object_19"].geometry}
          material={nodes["Object_19"].material}
        />
        <mesh
          name="Object_20"
          geometry={nodes["Object_20"].geometry}
          material={nodes["Object_20"].material}
        />
        <mesh
          name="Object_21"
          geometry={nodes["Object_21"].geometry}
          material={nodes["Object_21"].material}
        />
      </group>
    </group>
  );
};

export default Smartphone;
