import React, { useEffect, useRef } from "react";
import { useGLTF, useVideoTexture } from "@react-three/drei";
import gsap from "gsap";

const Laptop = (props: any) => {
  const group = useRef<any>();
  const { nodes, materials, animations }: any = useGLTF("/mac-draco.glb");

  const txt = useVideoTexture(
    props.texture ? props.texture : "/video/project1.mp4"
  );

  useEffect(() => {
    if (txt) {
      txt.flipY = false;
    }
  }, [txt]);

  useEffect(() => {
    gsap.from(group.current.rotation, {
      y: Math.PI / 2,
      duration: 1,
      ease: "power3.out",
    });
  }, [txt]);

  return (
    <group
      ref={group}
      rotation={[0, Math.PI, 0]}
      position={[0, 0, 0]}
      scale={1.2}
    >
      <group dispose={null}>
        <group rotation-x={-0.425} position={[0, -0.04, 0.41]}>
          <group position={[0, 2.96, -0.13]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              material={materials.aluminium}
              geometry={nodes["Cube008"].geometry}
            />
            <mesh
              material={materials["matte.001"]}
              geometry={nodes["Cube008_1"].geometry}
            />
            <mesh
              geometry={nodes["Cube008_2"].geometry}
              rotation={[Math.PI, Math.PI, Math.PI]}
            >
              <meshBasicMaterial map={txt} toneMapped={false} />
            </mesh>
          </group>
        </group>
        <mesh
          material={materials.keys}
          geometry={nodes.keyboard.geometry}
          position={[1.79, 0, 3.45]}
        />
        <group position={[0, -0.1, 3.39]}>
          <mesh
            material={materials.aluminium}
            geometry={nodes["Cube002"].geometry}
          />
          <mesh
            material={materials.trackpad}
            geometry={nodes["Cube002_1"].geometry}
          />
        </group>
        <mesh
          material={materials.touchbar}
          geometry={nodes.touchbar.geometry}
          position={[0, -0.03, 1.2]}
        />
      </group>
    </group>
  );
};

export default Laptop;
