import React, { useRef, useEffect} from "react";
import { useGLTF } from "@react-three/drei";
import gsap from "gsap";

const Lander = (props: any) => {
  const group = useRef<any>();
  const { nodes, materials }: any = useGLTF("/lander.glb");

  useEffect(() => {
    gsap.from(group.current.rotation, {
      y: Math.PI / 2,
      duration: 1,
      ease: "power3.out",
    });
  }, []);

  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lander_1.geometry}
          material={materials.blinn1SGSG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lander_2.geometry}
          material={materials.blinn5SGSG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lander_3.geometry}
          material={materials.blinn2SGSG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lander_4.geometry}
          material={materials.blinn4SGSG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lander_5.geometry}
          material={materials.blinn6SGSG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lander_6.geometry}
          material={materials.blinn9SGSG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lander_7.geometry}
          material={materials.initialShadingGrSG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lander_8.geometry}
          material={materials.blinn3SGSG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lander_9.geometry}
          material={materials.blinn7SGSG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lander_10.geometry}
          material={materials.blinn8SGSG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lander_11.geometry}
          material={materials.blinn12SGSG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.lander_12.geometry}
          material={materials.blinn10SGSG}
        />
      </group>
    </group>
  );
};

useGLTF.preload("/lander.glb");

export default Lander;
