import React, { useLayoutEffect, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { Decal, Float, useTexture } from "@react-three/drei";
import * as THREE from "three";

export const FLOOR_HEIGHT = 2.3;
export const NB_FLOORS = 2;

const Ball = (props: any) => {
  const [decal] = useTexture([props.imgUrl]);

  const ref = useRef<THREE.Mesh>(null);

  const { viewport } = useThree();

  const initialYPosition = useRef(0);

  useLayoutEffect(() => {
    if (ref.current) {
      initialYPosition.current = ref.current.position.y; // Capture initial y position on mount
    }
  }, []);

  useFrame((state, delta) => {
    if (ref.current && props.scroll) {
        // Calculate the target y position relative to the initial y position
        const targetY = initialYPosition.current + (viewport.height * -props.scroll.current);
        
        // Apply smooth transition using damp
        ref.current.position.y = THREE.MathUtils.damp(ref.current.position.y, targetY, 2, delta);
      }
  });
  return (
    <Float speed={1.75} rotationIntensity={1} floatIntensity={2}>
      <mesh
        castShadow
        receiveShadow
        scale={props.scale ? props.scale : 0.7}
        ref={ref}
        position={props.position}
      >
        <icosahedronGeometry args={[1, 1]} />
        <meshStandardMaterial
          color="#fff8eb"
          polygonOffset
          polygonOffsetFactor={-5}
          flatShading
        />
        <Decal
          position={[0, 0, 1]}
          rotation={[2 * Math.PI, 0, 6.25]}
          scale={1}
          map={decal}
        />
        <Decal
          position={[0, 0, -1]}
          rotation={[Math.PI, 0, Math.PI]}
          scale={1}
          map={decal}
        />
      </mesh>
    </Float>
  );
};

export default Ball;
