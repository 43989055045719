import React, { Suspense } from "react";
import { motion } from "framer-motion";
import { fadeIn, textVariant, staggerContainer } from "../util/motion";
import { technologies } from "../constant";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Preload } from "@react-three/drei";
import Ball from "./3Dobject/Ball";
import CanvasLoader from "./Loader";

const About = (props: any) => {
  return (
    <motion.section
      variants={staggerContainer()}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className="max-w-[112rem] mx-auto relative z-0 py-[10vh] px-[10vw]"
    >
      <motion.div variants={textVariant(0)}>
        <p className="text-customPink sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider font-orbitron">
          Introduction
        </p>
        <h2 className="text-white font-black md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px] font-orbitron">
          Overview.
        </h2>
      </motion.div>

      <motion.p
        variants={fadeIn("", "", 0.1, 1)}
        className="mt-4 text-customPink font-montserrat text-[17px] max-w-3xl leading-[30px]"
      >
        I'm a passionate full stack developer with a strong foundation in computer
        science, dedicated to building scalable, high-performance applications.
        I thrive on solving complex problems and creating user-friendly
        solutions that drive business growth. As a results-driven full-stack
        developer, I'm skilled in the full software development lifecycle, from
        crafting detailed specifications to delivering efficient, testable code.
        My experience in fast-paced startup environments has honed my ability to
        consistently meet deadlines and contribute to company success.
      </motion.p>

      <div className="flex flex-row justify-center gap-2 flex-wrap pt-4">
        {technologies.map((technology, index) => (
          <motion.div
            variants={fadeIn("down", "", 0.2 * index, 1)}
            className="w-28 h-28"
            key={technology.name}
          >
            <Canvas
              frameloop="demand"
              dpr={[1, 2]}
              gl={{ preserveDrawingBuffer: true }}
            >
              <ambientLight intensity={0.5} />
              <directionalLight position={[0, 0, 0.05]} />
              <Suspense fallback={<CanvasLoader />}>
                <OrbitControls enableZoom={false} />
                <Ball imgUrl={technology.icon} scale={2} />
              </Suspense>

              <Preload all />
            </Canvas>
          </motion.div>
        ))}
      </div>
    </motion.section>
  );
};

export default About;
