import React from "react";
import { Float, useGLTF } from "@react-three/drei";

const Spaceship = (props: any) => {
  const { nodes, materials }: any = useGLTF(props.url || "spaceship2.glb");

  return (
    <Float>
      <group {...props} dispose={null}>
        {nodes.Scene.children.map((m: any) => (
          <mesh
            key={1}
            castShadow
            receiveShadow
            geometry={m.geometry}
            material={materials.Planet_pack}
            rotation={[-Math.PI, 0, Math.PI]}
            scale={[0.166, 1.092, 0.176]}
            position={[0, -1, 0]}
          />
        ))}
      </group>
    </Float>
  );
};

export default Spaceship;
