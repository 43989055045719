import React from "react";
import { months } from "../constant";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Markdown from "markdown-to-jsx";

interface BlogData {
  id: number;
  creatorName: string;
  imageUrl: string;
  createdAt: Date;
  title: string;
  description: string;
  tempUrl?: string;
}

const BlogItem: React.FC<BlogData> = (props) => {
  const date = new Date(props.createdAt);
  const displayDate =
    months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();

  return (
    <motion.div
      className="w-[350px] h-[380px] overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Link to={`/blogPost/${props.id}`} className="no-underline">
        <div className="w-full rounded-lg overflow-hidden">
          <img
            src={props.tempUrl ? props.tempUrl : props.imageUrl}
            alt={props.title}
            className="w-full h-[230px] object-cover rounded-lg"
          />
        </div>

        <div className="flex flex-col items-start w-full mt-2">
          <span className="font-bold text-xl text-white">{props.title}</span>
          <div className="font-varela text-[#be9656] text-lg py-1 cursor-pointer">
            <span className="text-base">{displayDate}</span>
          </div>
          <Markdown className="font-varela text-white text-base mt-2 text-ellipsis overflow-hidden line-clamp-4">
            {props.description}
          </Markdown>
        </div>
      </Link>
    </motion.div>
  );
};

export default BlogItem;
