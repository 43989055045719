import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { staggerContainer, textVariant } from "../util/motion";
import { useHttpClient } from "../hooks/http-hook";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import { Link } from "react-router-dom";

const PromoteBlog = () => {
  const [loadedBlogs, setLoadedBlogs] = useState<any>([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BASE_URL}/api/blog/first-three-blogs`
        );
        let sortedBlogs = responseData.blogs.sort(
          (a: { createdAt: Date }, b: { createdAt: Date }) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setLoadedBlogs(sortedBlogs);
      } catch (err) {}
    };
    fetchBlogs();
  }, []);

  return (
    <section className="bg-coolMidnight py-16 px-10">
      <motion.div
        variants={staggerContainer()}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className="max-w-[112rem] mx-auto text-center relative z-0"
      >
        <motion.div variants={textVariant(0)} className="mb-12">
          <p className="text-customPink text-[18px] sm:text-[20px] uppercase tracking-wider font-orbitron">
            Discover My Latest Thoughts
          </p>
          <h2 className="text-white font-black md:text-[60px] sm:text-[50px] text-[40px] font-orbitron">
            Go Blog
          </h2>
        </motion.div>

        {isLoading && loadedBlogs.length == 0 ? (
          <div className="center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8">
            {loadedBlogs.map((blog: any, index: any) => {
              const date = new Date(blog.createdAt);

              // Format the date to "Month Year" format
              const formattedDate = date.toLocaleString("en-US", {
                year: "numeric",
                month: "long",
              });

              const maxLength = 100; // Maximum length of the paragraph before truncating
              const originalText = blog.description;
              // Truncate and add "..." if it's too long
              const shortenedText =
                originalText.length > maxLength
                  ? originalText.slice(0, maxLength) + "..."
                  : originalText;

              return (
                <div
                  key={index}
                  className="bg-black-200 border border-black-300 rounded-lg p-6 relative overflow-hidden group"
                >
                  <img
                    src={blog.tempUrl ? blog.tempUrl : blog.imageUrl}
                    alt={blog.title}
                    className="w-full h-48 object-cover rounded-lg mb-6"
                  />
                  <div className="absolute inset-0 bg-black-500 bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                  <div className="relative z-10">
                    <p className="text-customPink text-sm font-medium">
                      {formattedDate}
                    </p>
                    <h3 className="text-white text-xl font-bold mt-2">
                      {blog.title}
                    </h3>
                    <p className="text-white-500 text-sm mt-4">
                      {originalText.length > 100
                        ? originalText.slice(0, 100) + "..."
                        : originalText}
                    </p>

                    <Link
                      to={`/blogPost/${blog.id}`}
                      className="mt-4 inline-block text-customPink font-medium hover:underline"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <motion.div
          variants={textVariant(1)}
          className="mt-12 flex justify-center"
        >
          <Link
            to={`/Go-Blog`}
            className="bg-customPink text-white px-6 py-3 rounded-lg text-xl font-medium transition-all duration-300 hover:bg-white hover:text-black-200"
          >
            Visit Go Blog
          </Link>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default PromoteBlog;
