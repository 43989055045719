import reactjs from "../Assets/tech/reactjs.png";
import nodejs from "../Assets/tech/nodejs.png";
import typescript from "../Assets/tech/typescript.png";
import html from "../Assets/tech/html.png";
import javascript from "../Assets/tech/javascript.png";
import mongodb from "../Assets/tech/mongodb.png";
import css from "../Assets/tech/css.png";
import socket from "../Assets/tech/socket.png";
import firebase from "../Assets/tech/firebase.png";
import codeigniter from "../Assets/tech/codeigniter.png";
import php from "../Assets/tech/php.png";
import mysql from "../Assets/tech/mysql.png";
import redux from "../Assets/tech/redux.png";
import python from "../Assets/tech/python.png";
import tensorflow from "../Assets/tech/tensorflow.png";
import keras from "../Assets/tech/keras.png";
import discord from "../Assets/tech/discord.png";
import cplus from "../Assets/tech/cplus.png";
import OpenFrameworks from "../Assets/tech/OpenFrameworks.png";
import blender from "../Assets/tech/blender.png";
import dart from "../Assets/tech/dart.png";
import flutter from "../Assets/tech/flutter.png";
import windows from "../Assets/tech/windows.png";
import threejs from "../Assets/tech/threejs.svg";
import git from "../Assets/tech/git.png";
import docker from "../Assets/tech/docker.png";
import figma from "../Assets/tech/figma.png";
import teamcalai from '../Assets/project/teamcalai.png';
import finapp from '../Assets/project/finapp.png';
import mabar from '../Assets/project/mabar.png';
import budi from '../Assets/project/budi.png';
import lander from '../Assets/project/lander.png';
import upwork from '../Assets/project/upwork.png';
import kebunpintar from '../Assets/project/kebunpintar.jpg';
import putramulia from '../Assets/project/pmt.png';


import androidstudio from "../Assets/tech/androidstudio.png";
import spotlight1 from "../Assets/spotlight/spotlight1.png";
import spotlight2 from "../Assets/spotlight/spotlight2.png";
import spotlight3 from "../Assets/spotlight/spotlight3.png";
import spotlight4 from "../Assets/spotlight/spotlight4.png";
import spotlight5 from "../Assets/spotlight/spotlight5.png";

export const technologies = [
  {
    name: "React JS",
    icon: reactjs,
    position: [2, -5, 3],
  },
  {
    name: "Node JS",
    icon: nodejs,
    position: [-3, -1, -1],
  },
  {
    name: "TypeScript",
    icon: typescript,
    position: [4, -3, -5],
  },
  {
    name: "HTML 5",
    icon: html,
    position: [5, -5, -1],
  },
  {
    name: "MongoDB",
    icon: mongodb,
    position: [-1, 2, -3],
  },
  {
    name: "CSS 3",
    icon: css,
    position: [-3, -2, 4],
  },
  {
    name: "Android Studio",
    icon: androidstudio,
    position: [5, -1, -1],
  },
];

export const myProjects = [
  {
    title: "TEAMCAL AI - Scheduling Software",
    desc: "TEAMCAL AI is an AI-powered team solution built to simplify coordination with third parties, across companies, teams across time zones, and applications—effortlessly.",
    subdesc:
      "Implemented client-requested features with CodeIgniter3, PHP, jQuery, and Chart.js, ensuring smooth functionality through Unit and Integration Testing. Developed Outlook add-ins with JavaScript and Node.js, managed database updates with SQL and MySQL, and enhanced a mobile app with an AI chatbot using React Native and the latest language version.",
    href: "https://teamcal.ai/",
    texture: {
      default: "laptop",
      laptop: "/video/laptop1.mp4",
      mobile: '/video/mobile1.mp4',
      rocket: false,
    },
    logo: teamcalai,
    logoStyle: {
      backgroundColor: '#13202F',
      border: '0.2px solid #17293E',
      boxShadow: '0px 0px 60px 0px #2F6DB54D',
    },
    urltitle: "Check Live Website",
    spotlight: spotlight2,
    tags: [
      {
        id: 1,
        name: "CodeIgniter",
        path: codeigniter,
      },
      {
        id: 2,
        name: "PHP",
        path: php,
      },
      {
        id: 3,
        name: "MySQL",
        path: mysql,
      },
      {
        id: 4,
        name: "JavaScript",
        path: javascript,
      },
      {
        id: 5,
        name: "Node.js",
        path: nodejs,
      },
    ],
  },
  {
    title: 'Mabar - Gaming Platform',
    desc: 'Mabar is an innovative gaming platform that merges an online marketplace with social media tools to help gamers promote their profiles on a single platform.',
    subdesc:
      'Collaborated in a two-person team to build a full-stack application with React Native, Redux, Wasabi Bucket, Express.js, Node.js, and MongoDB, following Agile methodologies from concept to production. Partnered with an external vendor to create an Admin Dashboard using React and led a team of three in developing REST APIs for seamless vendor integration. Integrated payment processing with Midtrans Gateway, implemented real-time RoomChat using Socket, and configured Firebase Cloud Messaging for notifications.',
    href: 'https://www.youtube.com/watch?v=y5vE8y_f_OM',
    texture: {
      default: "mobile",
      laptop: null,
      mobile: '/video/mobile4.mp4',
      rocket: false,
    },
    logo: mabar,
    logoStyle: {
      backgroundColor: "#2A1816",
      border: "0.2px solid #36201D",
      boxShadow: "0px 0px 60px 0px #AA3C304D",
    },
    urltitle: "Coming Soon (App store & Play store)",
    spotlight: spotlight1,
    tags: [
      
      {
        id: 1,
        name: 'React.js',
        path: reactjs,
      },
      {
        id: 2,
        name: 'Redux',
        path: redux,
      },
      {
        id: 3,
        name: 'Node.js',
        path: nodejs,
      },
      {
        id: 4,
        name: 'MongoDB',
        path: mongodb,
      },
      {
        id: 6,
        name: 'Socket.io',
        path: socket,
      },
      {
        id: 7,
        name: 'Firebase',
        path: firebase,
      },
    ],
  },
  {
    title: 'FinApp - Financial Tracker',
    desc: 'FinApp is a personalized financial management application designed to track monthly expenses from daily purchases and subscriptions.',
    subdesc:
      'Developed with React Native, JavaScript, Node.js, and Express.js, FinApp allows users to monitor and analyze their monthly spending habits. Implemented schedule triggers in MongoDB Atlas using Aggregation pipelines to generate detailed monthly reports, enhancing data organization and accessibility. Crafted custom pie and line charts with SVG, React animation, and JavaScript to visually represent expenditure, providing an intuitive and engaging user experience.',
    href: 'https://github.com/selimut123/finApp',
    texture: {
      default: "mobile",
      laptop: null,
      mobile: '/video/mobile2.mp4',
      rocket: false,
    },
    logo: finapp,
    logoStyle: {
      backgroundColor: '#60f5a1',
      background:
        'linear-gradient(0deg, #60F5A150, #60F5A150), linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(208, 213, 221, 0.8) 100%)',
      border: '0.2px solid rgba(208, 213, 221, 1)',
      boxShadow: '0px 0px 60px 0px rgba(35, 131, 96, 0.3)',
    },
    urltitle: "Check Source Code",
    spotlight: spotlight3,
    tags: [
      {
        id: 1,
        name: 'React.js',
        path: reactjs,
      },
      {
        id: 2,
        name: 'Node.js',
        path: nodejs,
      },
      {
        id: 3,
        name: 'MongoDB',
        path: mongodb,
      },
      {
        id: 4,
        name: 'TypeScript',
        path: typescript,
      },
    ],
  },
  {
    title: 'Budi - Discord Chatbot',
    desc: 'Interactive Discord Chatbot is a Python-based chatbot designed to engage users with conversation and quizzes, utilizing advanced machine learning for natural interactions.',
    subdesc:
      'Developed using Python and the Discord API, the chatbot leverages a sequence model trained with a bag-of-words approach from JSON data. Implemented a convolutional neural network using Keras and TensorFlow for accurate response handling. Integrated a quiz section to enhance user engagement, providing an interactive experience within Discord.',
    href: 'https://github.com/selimut123/Budi-Discord-Bot',
    texture: {
      default: "laptop",
      laptop: '/video/laptop2.mp4',
      mobile: '/video/mobile3.mp4',
      rocket: false,
    },
    logo: budi,
    logoStyle: {
      backgroundColor: '#0E1F38',
      border: '0.2px solid #0E2D58',
      boxShadow: '0px 0px 60px 0px #2F67B64D',
    },
    urltitle: "Check Source Code",
    spotlight: spotlight4,
    tags: [
      {
        id: 1,
        name: 'Python',
        path: python,
      },
      {
        id: 2,
        name: 'TensorFlow',
        path: tensorflow,
      },
      {
        id: 3,
        name: 'Keras',
        path: keras,
      },
      {
        id: 4,
        name: 'Discord',
        path: discord,
      },
    ],
  },
  {
    title: 'Lander Simulation - Game',
    desc: 'Lander Simulation is a C++ game built with openFrameworks that simulates the challenges of landing a rocketship on the moon, featuring realistic physics and immersive gameplay.',
    subdesc:
      'Developed using C++ and openFrameworks, the simulation includes physics-based motion for accurate landings, object transformations for dynamic control, and particle systems with emitters for visual effects. Utilized Mesh for 3D modeling, colliders for precise collision detection, and spatial subdivision for efficient object management, creating a detailed and engaging lunar landing experience.',
    href: 'https://youtu.be/E6TUIRK8_7s',
    texture: {
      default: "laptop",
      laptop: '/video/laptop3.mp4',
      mobile: null,
      rocket: true,
    },
    logo: lander,
    logoStyle: {
      backgroundColor: '#1C1A43',
      border: '0.2px solid #252262',
      boxShadow: '0px 0px 60px 0px #635BFF4D',
    },
    urltitle: "Check Trailer",
    spotlight: spotlight5,
    tags: [
      {
        id: 1,
        name: 'C++',
        path: cplus,
      },
      {
        id: 2,
        name: 'OpenFrameworks',
        path: OpenFrameworks,
      },
      {
        id: 3,
        name: 'Blender',
        path: blender,
      },
    ],
  },
];

export const workExperiences = [
  {
    id: 1,
    name: 'TEAMCAL AI',
    pos: 'Software Engineer',
    duration: 'Feb 2024 - Present',
    title: "At TeamcalAI, I developed client-requested features and handled external integrations, including Outlook and Google Meet, to enhance functionality and streamline processes. I also contributed to mobile development, ensuring smooth user experiences across platforms.",
    icon: teamcalai,
    url: '/spaceship1.glb',
    tags: [
      {
        id: 1,
        name: "CodeIgniter",
        path: codeigniter,
      },
      {
        id: 2,
        name: "PHP",
        path: php,
      },
      {
        id: 3,
        name: "MySQL",
        path: mysql,
      },
      {
        id: 4,
        name: "JavaScript",
        path: javascript,
      },
      {
        id: 5,
        name: "Node.js",
        path: nodejs,
      },
    ],
  },
  {
    id: 2,
    name: 'Upwork',
    pos: 'Software Developer Freelance',
    duration: 'January 2024 - April 2024',
    title: "I work with clients to create engaging and functional web and mobile solutions. My focus is on delivering high-quality projects through regular communication and technical expertise.",
    icon: upwork,
    url: '/spaceship2.glb',
    tags: [
      {
        id: 1,
        name: "JavaScript",
        path: javascript,
      },
      {
        id: 2,
        name: "HTML",
        path: html,
      },
      {
        id: 3,
        name: "CSS",
        path: css,
      },
    ],
  },
  {
    id: 3,
    name: 'Kebun Pintar',
    pos: 'Software Developer Intern',
    duration: 'Sep 2021 - Dec 2021',
    title: "As a Software Developer Intern at Kebun Pintar, I integrated mobile app functionality in Flutter, designed UIs in Figma, and implemented REST API routes. I improved app performance with GetX for state management and resolved API issues in Laravel and PHP using unit and integration testing.",
    icon: kebunpintar,
    url: '/spaceship3.glb',
    tags: [
      {
        id: 1,
        name: "Flutter",
        path: flutter,
      },
      {
        id: 2,
        name: "Dart",
        path: dart,
      },
      {
        id: 3,
        name: "Android Studio",
        path: androidstudio,
      },
    ],
  },
  {
    id: 4,
    name: 'PT Putra Mulia Telecommunication',
    pos: 'IT Support',
    duration: 'July 2021 - Aug 2021',
    title: "As an IT Support at Putra Mulia Telecommunication, I deployed and configured laptops, managed upgrades, and replaced faulty components. I also collaborated to resolve client issues, implementing solutions to prevent recurrence.",
    icon: putramulia,
    url: '/spaceship4.glb',
    tags: [
      {
        id: 1,
        name: "Windows",
        path: windows,
      },
    ],
  },
];

export const months = [ "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December" ];
