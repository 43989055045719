import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import {
  useGLTF,
  OrbitControls,
  PerspectiveCamera,
  Stars,
} from "@react-three/drei";
import { technologies } from "../../constant";
import Ball from "../3Dobject/Ball";
import Blackhole from "../3Dobject/Blackhole";
import CanvasLoader from "../Loader";
import "./Header.css";

interface HeaderParams {
  scroll: any;
}

function Header({ scroll }: HeaderParams) {
  return (
    <div className="canvasContainer">
      <div className="headerContainer">
        <span className="title">
          Hi, I'm Bryan <br />{" "}
          <span style={{ color: "#FF4D6D" }}>Full Stack Developer</span>
          <br />
        </span>
        <span className="subtitle">
          Expertise in Creating Engaging Web and Mobile Solutions
        </span>
      </div>
      <div className="bg" />
      <Canvas dpr={[1.5, 2]} linear shadows>
        <ambientLight intensity={1} />
        <PerspectiveCamera makeDefault position={[0, 0, 16]} fov={75}>
          {/* Directional sunlight from a distant point, angled toward the center of the scene */}
          <directionalLight
            castShadow
            intensity={7}
            position={[50, 50, 50]} // Position the light farther away
            shadow-mapSize={[1024, 1024]} // Higher map size for sharper shadows
            shadow-bias={-0.0001}
          />
        </PerspectiveCamera>
        <Suspense fallback={<CanvasLoader />}>
          <Blackhole />
          {technologies.map((tech) => (
            <Ball key={tech.name} imgUrl={tech.icon} scroll={scroll} position={tech.position} />
          ))}
        </Suspense>
        <OrbitControls
          autoRotate
          enablePan={false}
          enableZoom={false}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 2}
        />
        <Stars radius={500} depth={50} count={1000} factor={10} />
      </Canvas>
      <div className="layer" />
      {/* <div className="absolute inset-0 bg-gradient-to-t from-[#3F0C58] via-transparent to-transparent pointer-events-none"></div> */}
    </div>
  );
}

export default Header;
