import React, {useEffect, useState} from 'react';

import './MainHeader.css'

function MainHeader(props){
    const [isVisible, setIsVisible] = useState(true);
    const [isTransparent, setIsTransparent] = useState(true);
    const [lastScrollPos, setLastScrollPos] = useState(0);
  
    // Handle scroll behavior
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      // Determine scroll direction
      if (currentScrollPos > lastScrollPos) {
        setIsVisible(false);
      } else {
        // Scrolling up
        setIsVisible(true);
      }
  
      setIsTransparent(currentScrollPos < 250);
  
      setLastScrollPos(currentScrollPos);
    };
  
    // Add event listener for scroll
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [lastScrollPos]);

    return <header className={`main-header ${isTransparent ? 'transparent' : `${isVisible ? 'visible' : 'hidden'}`}`}>
        {props.children}
    </header>
};

export default MainHeader;